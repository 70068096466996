import React from 'react';
import { initializeApollo } from 'services/apollo.ts';
import { ApolloProvider } from '@apollo/client';
import fetch from 'isomorphic-fetch';
import './src/styles/global.css';

const client = initializeApollo();

export const wrapRootElement = ({ element }) => {
	return <ApolloProvider client={client}>{element}</ApolloProvider>;
};
