import React from 'react';
import fetch from 'isomorphic-fetch';
import {
	ApolloClient,
	ApolloProvider,
	HttpLink,
	InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getUser } from '../services/auth';

let apolloClient;

// Initiate Apollo Client with default Public Fauna credentials and adjusted when token changes
function createApolloClient() {
	const link = new HttpLink({
		uri: 'https://graphql.fauna.com/graphql',
		fetch: fetch,
	});

	const authLink = setContext((_, { headers }) => {
		const token = getUser().token;

		return {
			headers: {
				...headers,
				authorization: token
					? `Bearer ${token}`
					: `Bearer ${process.env.GATSBY_FAUNA_PUBLIC}`,
			},
		};
	});

	return new ApolloClient({
		link: authLink.concat(link),
		cache: new InMemoryCache(),
	});
}

export function initializeApollo() {
	const _apolloClient = apolloClient ?? createApolloClient();
	if (!apolloClient) apolloClient = _apolloClient;
	return _apolloClient;
}

export function initializeApolloPostLogin() {
	const _apolloClient = createApolloClient();
	apolloClient = _apolloClient;
	return _apolloClient;
}

export function useApollo() {
	const store = useMemo(() => initializeApollo(initialState), [initialState]);
	return store;
}

export function onError({ graphQLErrors, networkError, operation, forward }) {
	if (graphQLErrors) {
		for (let err of graphQLErrors) {
			switch (err.extensions.code) {
				// Apollo Server sets code to UNAUTHENTICATED
				// when an AuthenticationError is thrown in a resolver
				case 'UNAUTHENTICATED':
					// Modify the operation context with a new token
					const oldHeaders = operation.getContext().headers;
					operation.setContext({
						headers: {
							...oldHeaders,
							authorization: getNewToken(),
						},
					});
					// Retry the request, returning the new observable
					return forward(operation);
			}
		}
	}

	// To retry on network errors, we recommend the RetryLink
	// instead of the onError link. This just logs the error.
	if (networkError) {
		console.log(`[Network error]: ${networkError}`);
	}
}
