import { gql } from 'graphql-tag';
import { initializeApolloPostLogin, initializeApollo } from 'services/apollo';

interface User {
  email?: string;
  role?: string;
  id?: number;
  token?: string;
}

interface LoginProps {
  email: string;
  password: string;
}

// Queries to create / login / logout

export const isBrowser = () => typeof window !== 'undefined';

export const getUser = () =>
  isBrowser() && window.localStorage.getItem('ftprintUser')
    ? JSON.parse(window.localStorage.getItem('ftprintUser'))
    : {};

// Logins the user
export const setUser = user =>
  window.localStorage.setItem('ftprintUser', JSON.stringify(user));

// Logins the user
export async function handleLogin({ email, password }: LoginProps) {
  const request = await fetch(`/api/sign-in`, {
    method: `POST`,
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  });

  const response = await request.json();

  // Sets the user variable post login
  setUser(response);

  // Reinitialize the apollo login with credentials
  if (response != {}) {
    initializeApolloPostLogin();
  }
  return response;
}

// Checks if user is logged in
export const isLoggedIn = () => {
  const user = getUser();
  return !!user.email;
};

// Logout function for users - logs out the user from all devices
export async function handleLogout() {
  const LOGOUT_USER = gql`
    mutation {
      logoutUser
    }
  `;

  setUser({});
  const client = initializeApollo();
  const { loading, error, data } = await client.mutate({
    mutation: LOGOUT_USER,
  });
  // If there are no errors, returns the uer object with password and token
  if (typeof error == 'undefined') {
    return {
      status: 200,
    };
  } else {
    return {
      status: 400,
    };
  }
}
